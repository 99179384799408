<template>
    <div class="content-box">
        <div v-loading="loading" class="mx-auto login p-20 box-shadow">
            <div class="header text-center mb-25">
                <h2 class="mb-0">{{ $t('register.title') }}</h2>
            </div>
            <el-divider />
            <el-form ref="ruleForm" :model="formData" :show-message="false" :status-icon="true" :rules="rules">
                <el-form-item prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-user" :placeholder="$t('login.email')" />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" :placeholder="$t('login.password')" show-password />
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="formData.re_password" prefix-icon="el-icon-unlock" :placeholder="$t('register.re_password')" show-password />
                </el-form-item>
                <el-form-item prop="invitation_code">
                    <el-input v-model="formData.invitation_code" prefix-icon="el-icon-present" :placeholder="$t('register.invitation_code')" />
                </el-form-item>
                <el-form-item prop="email_captcha">
                    <el-input v-model="formData.email_captcha" prefix-icon="el-icon-s-check" :placeholder="$t('user.email_captcha')">
                        <template slot="append">
                            <el-button v-if="sendEmailTimeout <= 0" plain type="success" native-type="button" @click="sendEmailCaptcha">{{ $t('send') }}</el-button>
                            <el-button v-else plain type="success" disabled native-type="button">Retry in {{ sendEmailTimeout }}s</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-divider />
            <div class="mt-30 mx-20">
                <el-button class="w-100" type="primary" @click="submitForm">{{ $t('submit') }}</el-button>
            </div>
            <div class="mt-30 mb-20 text-center font-size-14">
                {{ $t('register.already_have_an_account') }}
                <el-link class="color-primary is-link ml-3" @click="$nav.replace('login')">{{ $t('register.sign_in') }}</el-link>
            </div>
        </div>
    </div>
</template>

<script>
import { validEmail } from '@/services/validate'
export default {
    data() {
        return {
            loading: false,
            captchaSrc: null,
            formData: {
                email: '',
                password: '',
                invitation_code: this.$route.params.invitation_code,
                email_captcha: ''
            },
            timer: null,
            sendEmailTimeout: 0,
            rules: {
                email: [
                    { required: true, type: 'email', message: 'Please enter Email address', trigger: 'blur' }
                ],
                password: [
                    { required: true, type: 'string', min: 6, message: 'More than 6 characters', trigger: 'blur' }
                ],
                invitation_code: [
                    { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
                ],
                email_captcha: [
                    { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
                ]
            }
        }
    },
    created: function () {
        if(!this.formData.invitation_code && this.$route.query.invitation_code !== undefined) this.formData.invitation_code = this.$route.query.invitation_code
    },
    beforeDestroy: function () {
        if(this.timer === null) return
        window.clearInterval(this.timer)
        this.timer = null
    },
    methods: {
        sendEmailCaptcha: function (){
            if(validEmail(this.formData.email)){
                this.loading = true
                this.$request.post('register/email_captcha',this.formData).then(()=>{
                    this.loading = false
                    if (this.timer !== null) {
                        window.clearInterval(this.timer)
                    }
                    this.sendEmailTimeout = 120
                    this.timer = window.setInterval(() => {
                        this.sendEmailTimeout--
                        if(this.sendEmailTimeout <= 0){
                            window.clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }).catch(() => {
                    this.loading = false
                })
            }else{
                this.$message({
                    message: 'Please Enter Email Address',
                    type: 'error'
                })
            }
        },
        submitForm: async function () {
            await this.$refs.ruleForm.validate()
            if(this.formData.password != this.formData.re_password){
                this.$message({
                    message: 'The two passwords entered are inconsistent',
                    type: 'error'
                })
                return
            }
            this.loading = true
            try {
                await this.$request.post('register', this.formData)
                this.$store.commit('userEmail', this.formData.email)
                this.$nav.replace('login')
            } catch (error) {
                //
            }
            this.loading = false
        }
  }
}
</script>
<style lang="scss" scoped>
.login{
    width: 400px;
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: white;
    color: #333;
    border-radius: 8px;
}
</style>